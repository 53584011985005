import React from "react"

export default function myPortableTextComponents (styles) {
  const CustomBlockList = ({value}) => {
    return ( value.customList.length > 0 && (
        <div className={styles.customList}>
          <div className={styles.customListBar}> </div>
          <div className={styles.customListContent}>
            {value.customList.map((list, index) => (
              <div key={index} className={styles.listItem}>
                <p className={styles.listItemTitle}>
                  {list.listTitle}
                </p>
                <p className={styles.listItemSubtitle}>
                  {list.listSubTitle}
                </p>
                <p className={styles.listItemConent}>
                  {list.listContent}
                </p>
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const CustomBlockOrderedList = ({value}) => {
    return ( value.customList.length > 0 && (
        <div className={styles.customList}>
          <div className={styles.customListBar}> </div>
          <div className={styles.customListContent}>
            {value.customList.map((list, index) => (
              <div key={index} className={styles.listItem}>
                <p className={styles.listItemConent}>
                  {index + 1}. {list}
                </p>
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const CustomVideoIframe = ({value}) => {
    console.log(value)
    return ( value?.video_link && (
      <div className={styles.customVideo}>
        <div className={styles.customVideoTitle}>
          <h1 className={styles.h1Title}>
            {value.title}
          </h1>
        </div>
        <div className={styles.customVideoIframe}>
          <iframe src={value.video_link}></iframe>
        </div>
        { value.caption && (
          <div className={styles.customVideoCaption}>
            <p>
              <figcaption>
                {value.caption}
              </figcaption>
            </p>
          </div>
        )}
      </div>
    ))
  }
  
  const CustomBlockContentImage = ({value}) => {
    return (
      <div className={styles.customImage}>
        <div className={styles.customImageTitle}>
          <h1 className={styles.h1Title}>
            {value.title}
          </h1>
        </div>
        <div className={styles.customImageWrapper}>
          <img src={value.image.asset.url} alt={value.alt}/>
        </div>
        <div className={styles.customImageCaption}>
          <p>
            <figcaption>
              {value.caption}
            </figcaption>
          </p>
        </div>
      </div>
    )
  }

  const CustomTable = ({value}) => {
    return ( value?.rows.length > 0 && (
      <table className={styles.customTable}>
        <tbody className={styles.customTableBody}>
          {value?.rows.map((row,idx) => (
            <tr key={idx} className={idx == 0 ? styles.tableHeader : ''}>
              {row.cells.map((cell,idxc) => (
                  <td key={idxc}>
                    <p>{cell}</p>
                  </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    ))
  }

  return {
    types: {
      table: CustomTable,
      image: ({value}) => <img src={value.imageUrl} />,
      block_custom_list: CustomBlockList,
      block_custom_ordered_list: CustomBlockOrderedList,
      block_embed_video: CustomVideoIframe,
      block_image: CustomBlockContentImage,
      callToAction: ({value, isInline}) =>
        isInline ? (
          <a href={value.url}>{value.text}</a>
        ) : (
          <div className="callToAction">{value.text}</div>
        ),
    },
    marks: {
      link: ({children, value}) => {
        const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        )
      },
    },
    list: {
      bullet: ({children}) => (
        <div style={{ paddingLeft: "3.5em" }}>
          <ul>{children}</ul>
        </div>
      ),
      number: ({children}) => (
        <div style={{ paddingLeft: "3.5em" }}>
          <ol>{children}</ol>
        </div>
      ),
      // checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({children}) => <h1 className={styles.h1Title}>{children}</h1>,
      h2: ({children}) => <h2 className={styles.h2Title}>{children}</h2>,
      h3: ({children}) => <h3 className={styles.h3Title}>{children}</h3>,
      blockquote: ({children}) => <blockquote>{children}</blockquote>,
    },
  }
}



