/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function Seo({ description, lang, meta, title, image, keywrods }) {
  const { pathname } = useLocation()

  const { site } = useStaticQuery(
    graphql`
      query seo {
        site {
          siteMetadata {
            title
            description
            twitter
            image
            lang
            siteUrl
            author
          }
        }
      }
    `
  )
  const siteLink = site.siteMetadata.siteUrl
  const metaDescription = description
    ? description
    : site.siteMetadata.description
  const keywords = keywrods ? keywrods : site.siteMetadata.keywords
  const defaultTitle = title ? title : site.siteMetadata.title
  const imageUrl = image ? image : site.siteMetadata.image

  return (
    <></>
    // <Helmet
    //   defaultTitle={site.siteMetadata.title}
    //   titleTemplate={title}
    //   description={description}
    //   lang="en-US"
    //   meta={[
    //     {
    //       name: "author",
    //       content: "Devwiz",
    //     },
    //     {
    //       name: "keywords",
    //       content: keywords,
    //     },
    //     { name: "site_name", property: `og:site_name`, content: `Devwiz` },
    //     {
    //       name: "image",
    //       property: `og:image`,
    //       content: image,
    //     },
    //     { property: "og:locale", content: "en_US" },

    //     {
    //       property: `og:image:width`,
    //       content: `300`,
    //     },
    //     {
    //       property: `og:image:height`,
    //       content: `300`,
    //     },
    //     {
    //       name: "type",
    //       property: `og:type`,
    //       content: `website`,
    //     },
    //     {
    //       name: "url",
    //       property: `og:url`,
    //       content: siteLink,
    //     },
    //     {
    //       name: "title",
    //       property: `og:title`,
    //       content: title,
    //     },
    //   ].concat(meta)}
    //   link={[
    //     {
    //       rel: "stylesheet",
    //       type: "text/css",
    //       charset: "UTF-8",
    //       href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css",
    //     },
    //     {
    //       rel: "stylesheet",
    //       type: "text/css",
    //       charset: "UTF-8",
    //       href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css",
    //     },
    //   ]}
    // />
  )
}

Seo.defaultProps = {
  lang: `en-US`,
  meta: [],
  description: ``,
  link: [],
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
