import React from "react"
import { graphql } from "gatsby"
import { PortableText } from "@portabletext/react"
import myPortableTextComponents from "../../utils/case-study-serializer"
import { displayDate } from "../../utils/functions"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import Hero from "../../sections/hero/hero"

import * as hero_styles from "../../sections/hero/hero_index.module.scss"
import * as styles from "./case_study_inner.module.scss"

export default function CaseStudyInner({ data }) {
  const { hero, info, body, backgroundColor } = data.currentCaseStudy

  const renderInfoImage = () => {
    return (
      info?.client_logo && (
        <div className={styles.infoImage}>
          <img src={info?.client_logo.asset.url} />
        </div>
      )
    )
  }

  const renderInfoVideo = () => {
    return (
      info?.video_link && (
        <div className={styles.infoVideo}>
          <iframe src={info.video_link}></iframe>
        </div>
      )
    )
  }

  const renderInfoBE = () => {
    return (
      info?.backend_tech.length > 0 && (
        <div className={styles.infoCol}>
          <div className={styles.infoColTitle}>
            <p>BACKEND</p>
          </div>
          <div className={styles.infoColContent}>
            {info?.backend_tech.map((be, index) => (
              <p key={index}>{be}</p>
            ))}
          </div>
        </div>
      )
    )
  }

  const renderInfoFE = () => {
    return (
      info?.frontend_tech.length > 0 && (
        <div className={styles.infoCol}>
          <div className={styles.infoColTitle}>
            <p>FRONTEND</p>
          </div>
          <div className={styles.infoColContent}>
            {info?.frontend_tech.map((fe, index) => (
              <p key={index}>{fe}</p>
            ))}
          </div>
        </div>
      )
    )
  }

  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      <Seo title={"Home"} />
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={"case-study-inner-cont"}
      />
      <section className={styles.caseStudyInner} id={"case-study-inner-cont"}>
        <div className="default-container">
          <div className={styles.caseStudyInnerContainer}>
            <div className={styles.content}>
              <div className={styles.titleWrapper}>
                {renderInfoImage()}
                {renderInfoVideo()}
                <div className={styles.info}>
                  {renderInfoBE()}
                  {renderInfoFE()}
                </div>
              </div>
              <div className={styles.contentWrapper}>
                <PortableText
                  value={body}
                  components={myPortableTextComponents(styles)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query CaseStudyInnerPage($slug: String) {
    currentCaseStudy: sanityCaseStudy(slug: { current: { eq: $slug } }) {
      hero {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      info {
        video_link
        backend_tech
        frontend_tech
        client_logo {
          asset {
            url
          }
        }
      }
      body: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`
